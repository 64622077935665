<!-- 视频资讯 详情页 -->
<template>
<div class="videoDetail">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <div class="wCenter">
        <div class="content">
            <div class="video" v-if="config.c1&&config.c1.isShow">
                <video controls :src="item.videoUrl" :poster="item.coverImgUrl"></video>
                <p class="text textV">{{item.title}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getPageConfigByCode,
    getVideoInformationDetail
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: '',
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
            item:{}
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'videoListDetail/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c1 = this.config.c1
                    if (c1 && c1.isShow) {
                        this.getVideoInformationDetail();
                    }
                }
            })
        },
        getVideoInformationDetail(){
            getVideoInformationDetail(this.$route.query.id).then(res => {
                if (res.success&&res.result) {
                    this.item = res.result
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.videoDetail {
    .content {
        margin-top: 0.3rem;
        margin-bottom: .5rem;

        @media (max-width: 767px) {
            margin: 0.1rem 0 0;
        }

        .video {
            width: 10rem;
            margin:0 auto 0;
			text-align: center;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 0.5rem;
            }

            video {
                width: 10rem;
                margin-left: auto;
				margin-right:auto;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .title {
            color: #333333;
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        .text {
            color: #333333;
            font-size: 0.24rem;
            line-height: 2;
            margin-bottom: 0.2rem;
            text-align: center;
			&.textV{
				margin:.3rem auto 0;
			}
        }
    }
}
</style>
